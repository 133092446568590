import * as React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import './styles.scss'

const Error = ({ name }) => {
    return (
        <ErrorMessage
            name={name}
            component={'span'}
            className={'c-form-error'}
        />
    )
}

Error.propTypes = {
    /**
     * Error field name
     */
    name: PropTypes.string.isRequired
}

Error.defaultProps = {
    name: 'firstname'
}

export default Error
